.css-11zwyi3-MuiContainer-root{
    max-width: 100vw !important;
    margin: 0;
    padding: 0 !important;
    overflow: hidden;
}
.css-1c9pbic-MuiGrid-root{
    right:0;
}
.css-1ouluc0-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-root.Mui-focused fieldset{
    border-color: black;
}

.btn-login{
    align-self: flex-end !important;
}

.circle-loader {
    margin-bottom: 3.5em;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-left-color: #5cb85c;
    animation: loader-spin 1.2s infinite linear;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    width: 7em;
    height: 7em;
}
.load-complete {
    -webkit-animation: none;
    animation: none;
    border-color: #5cb85c;
    transition: border 500ms ease-out;
}
.checkmark {
    display: none;
}
.checkmark.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg) translate(55px, -20px);
}
.checkmark:after {
    opacity: 1;
    height: 3.5em;
    width: 1.75em;
    transform-origin: left top;
    border-right: 3px solid #5cb85c;
    border-top: 3px solid #5cb85c;
    content: '';
    position: absolute;
}
@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
   }
    100% {
        transform: rotate(360deg);
   }
}
@keyframes checkmark {
    0% {
        height: 0;
        width: 0;
        opacity: 1;
   }
    20% {
        height: 0;
        width: 1.75em;
        opacity: 1;
   }
    40% {
        height: 3.5em;
        width: 1.75em;
        opacity: 1;
   }
    100% {
        height: 3.5em;
        width: 1.75em;
        opacity: 1;
   }
}
