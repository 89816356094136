.nav-btn {
    background-color: #000;
    padding: 15px;
    color: #fff;
    border-radius: 30rem;
    margin-left: 20px;
}
@keyframes fade {
    0% { margin: 10px }
    100% { margin: 0 }
  }
  
.nav-scrolled {
    animation-name: fade;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    border-radius: 5px
  }

  

.nav-unscrolled {
    animation-name: unfade;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-direction: alternate;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    border: none;
  }

  @keyframes unfade {
    0% { opacity: 0; margin: 0 }
    100% { opacity: 1; margin: 10px }
  }

.profile-btn a {
    background-color: #000;
    height: 2.5rem;
    padding: 1rem;
    font-size: 16px;
    line-height: 1.5;
}

.payment-btn:hover {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
}

.payment-modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    background-color: #fff;
    border: none;
    border-radius: 20px;
    box-shadow: 24;
    padding: 60px 80px;
}

.payment-modal-details {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
}