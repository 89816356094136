body {
    background-color: #F2F2F2;
}

.logo-dashboard {
    max-width: 124px;
    width: 100%;
    max-height: 124px;
    height: 100%;
    object-fit: cover;
    transition: border 0.15s ease-in;
    border-radius: 100%;
    margin-top: 20px;
}

.logo-dashboard-2 {
    width: 8rem;
    height: 8rem;
    object-fit: cover;

    border: 1.5px solid #000;
    border-radius: 100%;
    margin-top: -4rem;
}

.logo-dashboard-2:hover {
    border-color: skyblue;
}

.cover-dashboard {
    max-width: 500px;
    width: 100%;
    max-height: 200px;
    height: 100%;
    object-fit: cover;

    border: 1.5px solid #000;
    border-radius: 20px;
    margin-top: 20px;
}

.cover-dashboard:hover {
    border-color: skyblue;
}

.social-links {
    width: 100%;
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}

.save-btn {
    background-color: #000 !important;

}

.qr-code-icon {
    background-color: #000;
    object-fit: contain;
    padding: 10px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    transition: transform 0.5s;
}

.qr-code-icon:hover {
    transform: scale(0.95);
} 