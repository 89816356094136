div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    display: flex;
    gap: 10px;
    scrollbar-width: none;
    background-color: #fff;
    margin: 0 20px;
  }
  
  .loading-elipsis::after {
      display: inline-block;
      animation: dotty steps(1,end) 2s infinite;
      content: '';
    }
    
    @keyframes dotty {
        0%   { content: ''; }
        25%  { content: '.'; }
        50%  { content: '..'; }
        75%  { content: '...'; }
        100% { content: ''; }
    }

  div.scrollmenu::-webkit-scrollbar{
    display: none;
  }

  div.scrollmenu a {
    display: inline-block;
    color: #000;
    text-align: center;
    padding: 10px 14px;
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 50px;
  }
  
  div.scrollmenu a:active {
    background-color: #000;
    color: #fff;
  }

  .active {
    background-color: #000;
    color: #fff !important;
  }