
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
body{
  background-color: #cfe8fc;
  padding: 0;
  margin: 0;
  font-family: 'Comfortaa';
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0 !important;
}